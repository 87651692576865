import React, { useState, useEffect } from "react";
import { FaTrash, FaChevronLeft, FaChevronRight } from "react-icons/fa"; 
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import './Joblist.css';
import '../ResponsiveCss/Responsive.css';
import Footer from '../Footer/Footer';
import axios from "axios";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import Date Picker CSS

export const EmployerPackage = () => {
    const [candidates, setCandidates] = useState([]);
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [candidateToDelete, setCandidateToDelete] = useState(null);
    const [jobList, setJobList] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 8;

    const [searchName, setSearchName] = useState('');
    const [statusFilter, setStatusFilter] = useState(''); // Status filter
    const [endDate, setEndDate] = useState(null); // Date filter

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get("https://backend.internsbee.in/api/newemployee/getallusers", {
                headers: { Authorization: token }
            });
            setCandidates(response.data.data);
            setFilteredCandidates(response.data.data); // Initially, set the filtered data to all candidates
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const DateFormate = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Filter logic
    useEffect(() => {
        const filtered = candidates.filter(candidate => {
            const nameMatches = candidate.empName.toLowerCase().includes(searchName.toLowerCase());
            const statusMatches = statusFilter ? (statusFilter === 'active' ? candidate.package.active : !candidate.package.active) : true;
            const dateMatches = endDate ? new Date(candidate.package.end_date).toDateString() === new Date(endDate).toDateString() : true;

            return nameMatches && statusMatches && dateMatches;
        });
        setFilteredCandidates(filtered);
    }, [searchName, statusFilter, endDate, candidates]);

    const handleDeleteClick = (candidate) => {
        setCandidateToDelete(candidate);
        setShowConfirmation(true);
    };

    const handleCancelDelete = () => {
        setShowConfirmation(false);
        setCandidateToDelete(null);
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await axios.delete(`https://backend.internsbee.in/api/newemployee/deleteEmployee/${candidateToDelete}`);

            if (response.status === 200) {
                alert(response.data.message);
                await fetchData();
            } else {
                console.error("Failed to delete shortlisted candidate");
            }
        } catch (error) {
            console.error("Error deleting shortlisted candidate:", error);
        }

        setShowConfirmation(false);
        setCandidateToDelete(null);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleViewMoreClick = (candidate) => {
        console.log('Selected Candidate:', candidate); // Debugging line
        openModal();
        setJobList(candidate);
    };
    

    const totalPages = Math.ceil(filteredCandidates.length / entriesPerPage);
    const paginatedApplicants = filteredCandidates.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <>
            <Navbar />
            <div className="flex h-screen">
                <Sidebar />
                <div className="flex-1 flex flex-col items-center p-4">
                    <h1 className="text-3xl font-bold mb-4 mt-8 text-center" style={{ fontFamily: 'Roboto, sans-serif' }}>
                        View Employer Details
                    </h1>

                    {/* Filters */}
                    <div className="flex gap-4 mb-6">
                        <input
                            type="text"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            placeholder="Search by Employer Name"
                            className=" border-2 border-amber-500 rounded p-2 "
                        />

                        <select
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                            className=" border-2 border-amber-500 rounded p-2 "
                        >
                            <option value="">All Status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>

                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            placeholderText="Filter by End Date"
                            className=" border-2 border-amber-500 rounded p-2 "
                        />
                    </div>

                    <div className="">
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Employer Name</th>
                                        <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Status</th>
                                        <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Package End Date</th>
                                        <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Type</th>
                                        <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Actions</th>
                                        <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Delete</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {paginatedApplicants.map((candidate, index) => (
                                        <tr key={candidate._id} className="hover:bg-gray-50">
                                            <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{capitalizeFirstLetter(candidate.empName)}</td>

                                            <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider flex items-center">
                                                <span className={`w-3 h-3 rounded-full ${candidate.package.active ? 'bg-green-500' : 'bg-red-500'} mr-2`}></span>
                                                {candidate.package.active ? 'Active' : 'Inactive'}
                                            </td>
                                            <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{candidate.package.active ? DateFormate(candidate.package.end_date) : ''}</td>
                                            <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{candidate.package.active ? capitalizeFirstLetter(candidate.package.type) : ''}</td>
                                            <td className="py-2 px-4 border-b">
                                                {candidate.package.active ?
                                                    <button
                                                        className="bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
                                                        onClick={() => handleViewMoreClick(candidate)}
                                                    >
                                                    <VisibilityOutlinedIcon className='' />
                                                    </button>
                                                    :
                                                    ''
                                                }
                                            </td>
                                            <td>
                                                <button onClick={() => handleDeleteClick(candidate._id)} className="bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer">
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="pagination flex justify-center items-center mt-4">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
                            >
                                <FaChevronLeft />
                            </button>
                            <div className="pagination-info mx-2 text-amber-600 px-1 py-1">
                            {currentPage} of {totalPages}
                          </div>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
                            >
                                <FaChevronRight />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for confirmation */}
            {showConfirmation && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg">
                        <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
                        <p className='p-2'>Are you sure you want to delete this candidate?</p>
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={handleCancelDelete}
                                className="bg-gray-200 hover:bg-gray-300 text-gray-700 px-4 py-2 rounded-lg mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleConfirmDelete}
                                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  <div className='flex justify-center'><h1 className="text-xl font-bold mb-4">Candidate Details</h1></div>
                        <p className='p-2'><strong>Employer Name:</strong> {capitalizeFirstLetter(jobList.empName)}</p>
                        <p className='p-2'><strong>Status:</strong> {jobList.package.active ? 'Active' : 'Inactive'}</p>
                        <p className='p-2'><strong>Package End Date:</strong> {jobList.package.active ? DateFormate(jobList.package.end_date) : 'N/A'}</p>
                        <p className='p-2'><strong>Type:</strong> {jobList.package.active ? capitalizeFirstLetter(jobList.package.type) : 'N/A'}</p>
                        {/* Add more details as necessary */}
                        <div className="flex justify-center p-2">
                            <button
                                onClick={closeModal}
                                className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
            
            
        </>
    );
};

export default EmployerPackage;