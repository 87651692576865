// import React from "react";
// import { Navigate } from "react-router-dom";

// const ProtectedRoute = ({ element: Component, authenticated, ...rest }) => {
//   return authenticated ? <Component {...rest} /> : <Navigate to="/" replace />;
// };

// export default ProtectedRoute;


import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const token = localStorage.getItem("token");
  return token ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
