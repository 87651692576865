import React, { useState, useEffect } from 'react';
import img1 from '../../../Assets/1.jpg';
import StarIcon from '@mui/icons-material/Star';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { Modal, Box } from '@mui/material';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const RecentApplicants = () => {
  const [appliedStudentInternshipList, setAppliedStudentInternshipList] = useState([]);
  const [selectedApplicant, setSelectedApplicant] = useState(null); // State for selected applicant
  const [open, setOpen] = useState(false); // State for modal open/close
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 4; // Adjust this value to set how many applicants to show per page

  const handleOpen = (applicant) => {
    setSelectedApplicant(applicant); // Set selected applicant details
    setOpen(true); // Open the modal
  };

  const handleClose = () => setOpen(false); // Close the modal

  const generateExcel = () => {
    // Filter data to include only the required fields
    const filteredData = appliedStudentInternshipList.map(item => ({
      'Recent Applicant Students': 'Recent Applicant Students', // Added static heading for the first column
      'Applied Date': DateFormate(item.appliedDate),
      'Candidate Name': item.InternName,
      'Email Address': item.InternEmail,
      'Contact Number': item.InternNumber,
      'EMP Name': item.empName,
      Location: item.location,
      Stipend: item.stipend,
      'Job Title': item.job_Title,
    }));

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert filtered data to worksheet
    const ws = XLSX.utils.json_to_sheet(filteredData);


    const columnWidths = [
      { wch: 25 }, // Recent Applicant Students column
      { wch: 25 }, // Applied Date column
      { wch: 25 }, // Candidate Name column
      { wch: 25 }, // Email Address column
      { wch: 25 }, // Contact Number column
      { wch: 25 }, // EMP Name column
      { wch: 25 }, // Location column
      { wch: 25 }, // Stipend column
      { wch: 25 }, // Job Title column
    ];
    ws['!cols'] = columnWidths;

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "RecentApplicants");

    // Generate the XLSX file and trigger the download
    XLSX.writeFile(wb, "RecentApplicants.xlsx");
  };

  const fetchAppliedStudentInternshipList = async () => {
    try {
      const res = await axios.get('https://backend.internsbee.in/api/applyInternship/applied/latest');
      setAppliedStudentInternshipList(res.data.data);
    } catch (error) {
      console.log('Applied Internship student List' + error);
    }
  };

  useEffect(() => {
    fetchAppliedStudentInternshipList();
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const DateFormate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const totalPages = Math.ceil(appliedStudentInternshipList.length / entriesPerPage);
  const paginatedApplicants = appliedStudentInternshipList.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <>
      <div className="w-full p-4">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
          <div className="mb-2 sm:mb-0">
            <h2 className="text-xl sm:text-2xl font-bold">Recent Applicants</h2>
          </div>
          <div className='mb-2'>
            <button className='btn btn-danger text-sm sm:text-base' onClick={generateExcel}>Generate Reports</button>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Candidates</th>
                <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Name</th>
                <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Location</th>
                <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Status</th>
                <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Applied Date</th>
                <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Action</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {paginatedApplicants.map((val, key) => (
                <tr key={key} className="hover:bg-gray-50">
                  <td className="whitespace-nowrap">
                    <div className='flex gap-2 px-4 py-1'>
                      <img src={img1} alt="" className='w-10 h-10 rounded-full' />
                      <StarIcon style={{ color: `${val.star}` }} />
                    </div>
                  </td>
                  <td className="whitespace-nowrap">
                    <p className="text-left text-sm font-medium text-gray-700 tracking-wider">{val.InternName}</p>
                  </td>
                  <td className="whitespace-nowrap">
                    <p className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{val.location}</p>
                  </td>
                  <td className="whitespace-nowrap">
                    {val.status.toLowerCase() === 'shortlisted' ? (
                      <button className='statusBtnAcc'>{capitalizeFirstLetter(val.status)}</button>
                    ) : (
                      <button className='statusBtnPend'>{capitalizeFirstLetter(val.status)}</button>
                    )}
                  </td>
                  <td className="whitespace-nowrap">
                    <p className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{val.appliedDate}</p>
                  </td>
                  <td className="whitespace-nowrap">
                    <div className="px-6 py-1 text-left">
                      <button className='bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer' onClick={() => handleOpen(val)}>
                        <VisibilityOutlinedIcon className='' />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>
        {/* Pagination */}
        <div className="pagination flex justify-center items-center mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
          >
            <FaChevronLeft />
          </button>
          <div className="pagination-info mx-2 text-amber-600 px-1 py-1">
            {currentPage} of {totalPages}
          </div>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
          >
            <FaChevronRight />
          </button>
        </div>
      </div>

      {/* Modal for viewing applicant details */}
      <Modal open={open} onClose={handleClose}>
        <Box className="modal-box">
          {selectedApplicant && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <div className='flex justify-center'><h1 className="text-xl font-bold mb-4">Applicant Details</h1></div>

                <p className='p-2'><strong>Name:</strong> {selectedApplicant.InternName}</p>
                <p className='p-2'><strong>Email:</strong> {selectedApplicant.InternEmail}</p>
                <p className='p-2'><strong>Contact Number:</strong> {selectedApplicant.InternNumber}</p>
                <p className='p-2'><strong>Location:</strong> {selectedApplicant.location}</p>
                <p className='p-2'><strong>Job Title:</strong> {selectedApplicant.job_Title}</p>
                <p className='p-2'><strong>Status:</strong> {capitalizeFirstLetter(selectedApplicant.status)}</p>
                <p className='p-2'><strong>Applied Date:</strong> {DateFormate(selectedApplicant.appliedDate)}</p>

                <div className='flex justify-center p-2'>
                  <button className="mt-4 bg-red-500 text-white px-4 py-2 rounded" onClick={handleClose}>Close</button>
                </div>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default RecentApplicants;
