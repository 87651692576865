import React from 'react'
import Navbar from '../Navbar/Navbar'
import Sidebar from '../Sidebar/Sidebar'

const GenerateReport = () => {
  return (
    <>

       <Navbar />
      <div className="displaycontent flex h-screen">
        <Sidebar />

        <h1 className='fs-3 fw-bold'>Generate Reports</h1>
        </div>








    </>
  )
}

export default GenerateReport