import React, { useState, useEffect } from 'react';
import './ViewProfile.css';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Navbar/Navbar';
import axios from 'axios'
const ViewProfile = () => {
  const [adminDetails, setAdminDetails] = useState({});
  const [userid,setId] = useState('')
  const [isEdit, setIsEdit] = useState(false);


  useEffect(() => {
    
    const savedId= localStorage.getItem('userId')
    console.log(savedId)
    setId(savedId)
    console.log(userid)
    fetchAdminDetails(userid);
  }, []);
  const fetchAdminDetails = async (id) => {
    try {
      console.log(id)
      const adminID = id || localStorage.getItem('userId')
      const response = await axios.get(`https://backend.internsbee.in/api/adminlogin/${adminID}`);
      const data = await response.data;
      setAdminDetails(data);
      console.log(adminDetails)
    } catch (error) {
      console.error('Error fetching admin details:', error);
    }
  };

  const editProfile = async() => {
    try{
      const id = localStorage.getItem('userId') || userid ;
      const response = await axios.patch(`https://backend.internsbee.in/api/adminlogin/${id}`,adminDetails);
      if(response.status===200){

        const data = response.data;
        setAdminDetails(data);
        alert('Admin Details Updated.')
        setIsEdit(false);
      }
    }
    catch(e){
      console.log(e);
    }
  }

  const profileInputHandle = (e) => {

    const{name, value} = e.target;
    setAdminDetails({...adminDetails, [name]:value}, );
  }



  return (
    <>
      <Navbar />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex items-center justify-center w-full">
          {adminDetails && (
            <div className="bg-white p-8 rounded shadow-md profile-container">

              {isEdit ?  
              (
                <>
              <h2 className="text-2xl font-bold mb-4">Edit Details</h2> 
              <input onChange={profileInputHandle} value={adminDetails.empName} name='empName' type='text' placeholder="Enter your name..." required/>
              <input onChange={profileInputHandle} value={adminDetails.email} name='email' type='text' placeholder="Enter your email..." required/>

              <div className="edit-btn-container"> 
              <button className='edit-profile-btn bg-amber-500' type='submit' onClick={editProfile}>Submit</button>
              <button className='edit-profile-btn bg-amber-500' onClick={()=>{setIsEdit(!isEdit)}}>Cancel</button>
              </div>
              </> )
              : 
              ( 
              <>
              <h2 className="text-2xl font-bold mb-4">Admin Details</h2>  
              <p>
                <strong>Name:</strong> {adminDetails.empName}</p>
              <p>
                <strong>Email:</strong> {adminDetails.email}
              </p>
              <div className="edit-btn-container"> 
              <button className='edit-profile-btn bg-amber-500' onClick={()=>{setIsEdit(!isEdit)}}>Edit</button>
              </div>
              </>
               )}
                
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewProfile;
