import React, { useState, useEffect } from "react";
import { FaTrash, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./StudentDetails.css";
import axios from "axios";
import DatePicker from 'react-datepicker';
import { FaCalendarAlt } from "react-icons/fa";

const StudentDetails = () => {
  const [student, setStudent] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [candidateToDelete, setCandidateToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(7);

  // Filter States
  const [nameFilter, setNameFilter] = useState("");
  const [freemiumFilter, setFreemiumFilter] = useState("");
  const [premiumFilter, setPremiumFilter] = useState("");

  const [freemiumStartDate, setFreemiumStartDate] = useState(null);
  const [freemiumEndDate, setFreemiumEndDate] = useState(null);
  const [premiumStartDate, setPremiumStartDate] = useState(null);
  const [premiumEndDate, setPremiumEndDate] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleViewMoreClick = (candidate) => {
    openModal();
    setCandidateDetails(candidate);
  };

  const fetchStudents = async () => {
    try {
      const response = await axios.get("https://backend.internsbee.in/api/newstudents/getallusers");
      setStudent(response.data.data);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  const handleDeleteClick = (candidate) => {
    setCandidateToDelete(candidate);
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setCandidateToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`https://backend.internsbee.in/api/newstudents/deleteuser/${candidateToDelete}`);
      if (response.status === 200) {
        alert(response.data.message);
        await fetchStudents();
      } else {
        console.error("Failed to delete student");
      }
    } catch (error) {
      console.error("Error deleting student:", error);
    }
    setShowConfirmation(false);
    setCandidateToDelete(null);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const DateFormate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const totalPages = Math.ceil(student.length / entriesPerPage);

  // Filter the students based on filter criteria
  // Check if a date falls within the given range
  const isDateInRange = (date, startDate, endDate) => {
    const d = new Date(date);
    return (!startDate || d >= new Date(startDate)) && (!endDate || d <= new Date(endDate));
  };

  // Filter students based on criteria
  const filteredStudents = student.filter((stud) => {
    const matchesName = stud.fullName.toLowerCase().includes(nameFilter.toLowerCase());
    const matchesFreemium = freemiumFilter ? stud.freemium.active === (freemiumFilter === "Active") : true;
    const matchesPremium = premiumFilter ? stud.premium.active === (premiumFilter === "Active") : true;

    const matchesFreemiumDate = stud.freemium.active
      ? isDateInRange(stud.freemium.endDate, freemiumStartDate, freemiumEndDate)
      : true;
    const matchesPremiumDate = stud.premium.active
      ? isDateInRange(stud.premium.endDate, premiumStartDate, premiumEndDate)
      : true;

    return matchesName && matchesFreemium && matchesFreemiumDate && matchesPremium && matchesPremiumDate;
  });

  // Paginate the filtered students
  const paginatedStudents = filteredStudents.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <>
      <Navbar />
      <div className="displaycontent flex h-screen">
        <Sidebar />
        <div className="flex-1 flex justify-center">
          <div className="max-w-full p-4">
            <h1 className="EmployerHeading-E text-3xl font-bold mb-4 mt-8 relative text-center">
              View Student Package Details
            </h1>
            {/* Filter Section */}
            <div className="filter-section mb-4 flex gap-10">
              <input
                type="text"
                placeholder="Search by Student Name"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
                className="w-full p-2 pl-10 pr-3 border-2 border-amber-500 rounded"
              />
              <select
                value={freemiumFilter}
                onChange={(e) => setFreemiumFilter(e.target.value)}
                className="w-full p-2 pl-10 pr-3 border-2 border-amber-500 rounded"
              >
                <option value="">All Freemium Status</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
              <select
                value={premiumFilter}
                onChange={(e) => setPremiumFilter(e.target.value)}
                className="w-full p-2 pl-10 pr-3 border-2 border-amber-500 rounded"
              >
                <option value="">All Premium Status</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
              {/* <div className="flex gap-10 mt-4">
                <div>
                  <p className='p-2'>Freemium End Date</p>
                  <label>Start date</label>
                  <input
                    type="date"
                    value={freemiumStartDate || ""}
                    onChange={(e) => setFreemiumStartDate(e.target.value)}
                    className="border p-2 rounded"
                  />
                  <label>End date</label>
                  <input
                    type="date"
                    value={freemiumEndDate || ""}
                    onChange={(e) => setFreemiumEndDate(e.target.value)}
                    className="border p-2 rounded"
                  />
                </div>

                <div>
                  <p className='p-2'>Premium End Date</p>
                  <label>Start date</label>
                  <input
                    type="date"
                    value={premiumStartDate || ""}
                    onChange={(e) => setPremiumStartDate(e.target.value)}
                    className="border p-2 rounded"
                  />
                  <label>End date</label>
                  <input
                    type="date"
                    value={premiumEndDate || ""}
                    onChange={(e) => setPremiumEndDate(e.target.value)}
                    className="border p-2 rounded"
                  />
                </div>
              </div>*/}
            </div>
            <div className="EmployerScroll flex gap-10 relative">
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
                  <thead className="bg-gray-100">
                    <tr>

                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">
                        Student Name
                      </th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">
                        Freemium Status
                      </th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">
                        Freemium End Date
                      </th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">
                        Premium Status
                      </th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">
                        Premium End Date
                      </th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">
                        Actions
                      </th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {paginatedStudents.map((stud, index) => (
                      <tr key={stud._id} className="hover:bg-gray-50">

                        <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">
                          {stud.fullName}
                        </td>
                        <td className="py-2 px-4 border-b text-lg flex items-center">
                          <span className={`w-3 h-3 rounded-full ${stud.freemium.active ? 'bg-green-500' : 'bg-red-500'} mr-2`}></span>
                          {stud.freemium.active ? 'Active' : 'Inactive'}
                        </td>
                        <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">
                          {stud.freemium.active ? DateFormate(stud.freemium.endDate) : 'Not Active'}
                        </td>
                        <td className="py-2 px-4 border-b text-lg flex items-center">
                          <span className={`w-3 h-3 rounded-full ${stud.premium.active ? 'bg-green-500' : 'bg-red-500'} mr-2`}></span>
                          {stud.premium.active ? 'Active' : 'Inactive'}
                        </td>
                        <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">
                          {stud.premium.active ? DateFormate(stud.premium.endDate) : 'Not Active'}
                        </td>

                        <td className="whitespace-nowrap">
                          <div className="px-4 py-1 text-left">
                            <button className="bg-amber-400 text-black hover:bg-amber-200 p-1 sm:p-2 cursor-pointer" onClick={() => handleViewMoreClick(stud)}>
                              <VisibilityOutlinedIcon className="text-xs sm:text-sm" />
                            </button>
                          </div>
                        </td>
                        <td className="py-2 px-4 border-b">
                          <button className="bg-amber-400 text-black hover:bg-amber-200 p-2 sm:p-2 cursor-pointer" onClick={() => handleDeleteClick(stud._id)}>
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>



              <div>
                {/* Confirmation Modal */}
                {showConfirmation && (
                  <div className="confirmation-modal">
                    <p className='p-2'>Are you sure you want to delete this student?</p>
                    <button onClick={handleConfirmDelete}>Yes</button>
                    <button className="not-delete" onClick={handleCancelDelete}>
                      No
                    </button>
                  </div>
                )}

                {isModalOpen && (
                  <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <div className='flex justify-center'><h1 className="text-xl font-bold mb-4">Student Details</h1></div>
    
                      <p className='p-2'>
                        <strong>Full Name:</strong> {candidateDetails.fullName}
                      </p>
                      <p className='p-2'>
                        <strong>Freemium Status:</strong>{" "}
                        {candidateDetails.freemium.active ? "Active" : "Inactive"}
                      </p>
                      <p className='p-2'>
                        <strong>Premium Status:</strong>{" "}
                        {candidateDetails.premium.active ? "Active" : "Inactive"}
                      </p>
                      <div className='flex justify-center p-2'>
                      <button onClick={closeModal} className="mt-4 bg-red-500 text-white px-4 py-2 rounded">
                        Close
                      </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Pagination */}
            <div className="pagination flex justify-center items-center mt-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
              >
                <FaChevronLeft />
              </button>
              <div className="pagination-info mx-2 text-amber-600 px-1 py-1">
                {currentPage} of {totalPages}
              </div>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
              >
                <FaChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default StudentDetails;
