import React, { useState, useEffect } from 'react'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const InternshipListed = () => {
    const Modelinternship = ({ isOpen, onClose, internship }) => {
        if (!isOpen) return null;

        return (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4 sm:p-6">
                <div className="bg-white w-full sm:max-w-lg p-4 sm:p-6 rounded-lg shadow-lg">
                    <div className="flex justify-center">
                        <h1 className="text-lg sm:text-xl font-bold mb-4 text-center">{internship.job_Title}</h1>
                    </div>
                    <p className="p-1 sm:p-2"><strong>Company Name:</strong> {internship.company_Name}</p>
                    <p className="p-1 sm:p-2"><strong>Posted By:</strong> {internship.empName}</p>
                    <p className="p-1 sm:p-2"><strong>Contact Email:</strong> {internship.empEmail}</p>
                    <p className="p-1 sm:p-2"><strong>Contact Phone:</strong> {internship.empPhone}</p>
                    <p className="p-1 sm:p-2"><strong>Location:</strong> {internship.location}</p>
                    <p className="p-1 sm:p-2"><strong>Job Type:</strong> {internship.job_Type}</p>
                    <p className="p-1 sm:p-2"><strong>Position Duration:</strong> {internship.position}</p>
                    <p className="p-1 sm:p-2"><strong>Stipend:</strong> ₹{internship.stipend}</p>
                    <p className="p-1 sm:p-2"><strong>Skills Required:</strong> {internship.skills}</p>
                    <p className="p-1 sm:p-2"><strong>Job Description:</strong> {internship.job_Description}</p>
                    <p className="p-1 sm:p-2"><strong>Start Date:</strong> {new Date(internship.start_Date).toLocaleDateString()}</p>
                    <p className="p-1 sm:p-2"><strong>End Date:</strong> {new Date(internship.end_Date).toLocaleDateString()}</p>
                    <div className="flex justify-center p-2">
                        <button className="mt-4 bg-red-500 text-white text-sm sm:text-base px-3 sm:px-4 py-2 rounded" onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>

        );
    };

    const [appliedStudentInternshipList, setAppliedStudentInternshipList] = useState([]);
    const [latestInternship, setLatestInternship] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const [selectedInternship, setSelectedInternship] = useState(null); // State for selected internship
    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 4;

    const generateExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(latestInternship);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Internships");

        // Download the Excel file
        XLSX.writeFile(workbook, "Internship_List.xlsx");
    };


    const fetchLatestFiveIntership = async () => {
        try {
            const res = await axios.get('https://backend.internsbee.in/api/postinternship/internship/recentpost');
            if (res.status === 200) {
                setLatestInternship(res.data.data);
            }
        } catch (error) {
            console.log('Latest Intership ' + error)
        }
    };

    useEffect(() => {
        fetchLatestFiveIntership();
    }, []);

    const DateFormate = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleViewClick = (internship) => {
        setSelectedInternship(internship);
        setIsModalOpen(true);
    };

    const totalPages = Math.ceil(latestInternship.length / entriesPerPage);
    const paginatedInternships = latestInternship.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <>
            {/* Latest Internship */}
            <div className="w-full p-4">
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
                    <div className="mb-2 sm:mb-0">
                        <h2 className="text-xl sm:text-2xl font-bold">Recent Internship Listed</h2>
                    </div>
                    <div>
                        <button className="btn btn-danger text-sm sm:text-base" onClick={generateExcel}>
                            Generate Reports
                        </button>
                    </div>
                </div>

                <div>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Title</th>
                                    <th className="px-2 py-1 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Posted By</th>
                                    <th className="px-2 py-1 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Contact No.</th>
                                    <th className="px-2 py-1 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {paginatedInternships.map((val, key) => (
                                    <tr key={key} className="hover:bg-gray-50">
                                        <td className="whitespace-nowrap">
                                            <div className="px-4 py-1">
                                                <h6 className="text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{val.job_Title}</h6>
                                                <p className="text-xs text-left text-gray-600">{val.location}</p>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap">
                                            <p className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{val.empEmail}</p>
                                        </td>
                                        <td className="whitespace-nowrap">
                                            <p className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{val.empPhone}</p>
                                        </td>
                                        <td className="whitespace-nowrap">
                                            <div className="px-2 py-1 text-left">
                                                <button className="bg-amber-400 text-black hover:bg-amber-200 p-1 sm:p-2 cursor-pointer" onClick={() => handleViewClick(val)}>
                                                    <VisibilityOutlinedIcon className="text-xs sm:text-sm" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>

                {/* Pagination */}
                <div className="pagination flex justify-center items-center mt-4">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
                    >
                        <FaChevronLeft />
                    </button>
                    <div className="pagination-info mx-2 text-amber-600 px-1 py-1">
                        {currentPage} of {totalPages}
                    </div>
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
                    >
                        <FaChevronRight />
                    </button>
                </div>
            </div>

            {/* Modal for viewing selected internship */}
            <Modelinternship
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                internship={selectedInternship}
            />
        </>
    );
};

export default InternshipListed;
