import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import "./Candidates.css";
import "../ResponsiveCss/Responsive.css";
import Footer from './../Footer/Footer';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { FaChevronLeft, FaChevronRight, FaSearch } from "react-icons/fa";
const Candidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Added searchTerm state
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [candidateToDelete, setCandidateToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 6;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchData = async () => {
    try {
      const response = await fetch("https://backend.internsbee.in/api/studentsdetails");
      const data = await response.json();
      setCandidates(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteClick = (candidate) => {
    setCandidateToDelete(candidate);
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setCandidateToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(`https://backend.internsbee.in/api/studentsdetails/${candidateToDelete._id}`, {
        method: "DELETE",
      });

      if (response.ok) {
        await fetchData();
      } else {
        console.error("Failed to delete candidate");
      }
    } catch (error) {
      console.error("Error deleting candidate:", error);
    }

    setShowConfirmation(false);
    setCandidateToDelete(null);
  };

  const handleViewMoreClick = (candidate) => {
    openModal();
    setCandidateDetails(candidate);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const DateFormate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  // Filter candidates based on the search term
  const filteredCandidates = candidates.filter(candidate => {
    const fullName = `${candidate.firstName} ${candidate.lastName}`.toLowerCase();
    const candidateCity = candidate.city ? candidate.city.toLowerCase() : '';
    const candidateDesignation = typeof candidate.stream === 'string' ? candidate.stream.toLowerCase() : '';

    return (
      fullName.includes(searchTerm.toLowerCase()) ||
      candidateCity.includes(searchTerm.toLowerCase()) ||
      candidateDesignation.includes(searchTerm.toLowerCase())
    );
  });


  const totalPages = Math.ceil(filteredCandidates.length / entriesPerPage);
  const paginatedCandidates = filteredCandidates.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <>
      {/* Navbar and Sidebar components */}
      <Navbar />
      <div className="displaycontent flex h-screen">
        <Sidebar />
        <div className="candidatepadding flex justify-start md:flex-row md:mr-14 p-4 ml-20">
          <div className="CandidateMain">
            <h1 className="text-3xl font-bold mb-4 mt-8 flex justify-center" style={{ fontFamily: 'Roboto, sans-serif' }}>
              View Candidate Details
            </h1>

            {/* Search Bar */}
            <div className="search-bar relative mb-6 w-[80%] mx-auto">
              <input
                type="text"
                placeholder="Search by Candidate's Name or Location"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input
                className="w-full p-2 pl-10 pr-3 border-2 border-amber-500 rounded" // Add left padding for the icon and right padding for space
              />
              <span className="">
                <FaSearch className="absolute right-0 top-0 mt-[10px] mr-2" /> {/* Search Icon */}
              </span>
            </div>
            <div className="">
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Sr No</th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Candidates Name</th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Location</th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Designation</th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Profile</th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Actions</th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Delete</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {paginatedCandidates.map((candidate, index) => (
                      <tr key={candidate._id} className="hover:bg-gray-50">
                        <td className="py-2 px-4 border-b text-lg">{(currentPage - 1) * entriesPerPage + index + 1}</td>
                        <td className="py-2 px-4 border-b text-lg">
                          {`${candidate.firstName} ${candidate.lastName}`}
                        </td>
                        <td className="py-2 px-4 border-b text-lg">{candidate.city}</td>
                        <td className="py-2 px-4 border-b text-lg">{candidate.stream}</td>
                        <td className="py-2 px-4 border-b">
                          {candidate.profile_pic && (
                            <img
                              src={candidate.profile_pic}
                              alt={`${candidate.firstName} ${candidate.lastName}`}
                              className="rounded-full h-10 w-10 object-cover"
                            />
                          )}
                        </td>
                        <td className="whitespace-nowrap">
                          <div className="px-2 py-1 text-left">
                            <button className="bg-amber-400 text-black hover:bg-amber-200 p-1 sm:p-2 cursor-pointer" onClick={() => handleViewMoreClick(candidate)}>
                              <VisibilityOutlinedIcon className="text-xs sm:text-sm" />
                            </button>
                          </div>
                        </td>
                        <td className="py-2 px-4 border-b">
                          <div className="px-2 py-1 text-left">
                            <button className="bg-amber-400 text-black hover:bg-amber-200 p-2 sm:p-2 cursor-pointer" onClick={() => handleDeleteClick(candidate)}>
                              <FaTrash />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Pagination */}
              <div className="pagination flex justify-center items-center mt-4">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
                >
                  <FaChevronLeft />
                </button>
                <div className="pagination-info mx-2 text-amber-600 px-1 py-1">
                  {currentPage} of {totalPages}
                </div>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
                >
                  <FaChevronRight />
                </button>
              </div>
            </div>

            {/* Confirmation Modal for Deletion */}
            {showConfirmation && (
              <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-md">
                  <h2 className="text-lg font-bold">Confirm Deletion</h2>
                  <p className='p-2'>Are you sure you want to delete {candidateToDelete?.firstName} {candidateToDelete?.lastName}?</p>
                  <div className="mt-4 flex justify-end">
                    <button onClick={handleCancelDelete} className="mr-4 bg-gray-300 p-2 rounded">Cancel</button>
                    <button onClick={handleConfirmDelete} className="bg-red-500 text-white p-2 rounded">Delete</button>
                  </div>
                </div>
              </div>
            )}

            {/* Candidate Details Modal */}
            {isModalOpen && candidateDetails && (
              <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  <div className='flex justify-center'><h1 className="text-xl font-bold mb-4">Candidate Details</h1></div>
                  <p className='p-2'><strong>Name:</strong>{`${candidateDetails.firstName} ${candidateDetails.lastName}`}</p>
                  <p className='p-2'><strong>Location:</strong> {candidateDetails.city}</p>
                  <p className='p-2'><strong>Designation:</strong> {candidateDetails.stream}</p>
                  <p className='p-2'><strong>Date of Birth:</strong> {DateFormate(candidateDetails.dateOfBirth)}</p>
                  <div className='flex justify-center p-2'>
                    <button className="mt-4 bg-red-500 text-white px-4 py-2 rounded" onClick={closeModal}>Close</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

    </>
  );
};

export default Candidates;
