import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Footer from "./../Footer/Footer";
import { FaTrash, FaChevronLeft, FaChevronRight, FaSearch } from "react-icons/fa";
import "./ShorlistedCandidates.css";
import "../ResponsiveCss/Responsive.css";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const ShortlistedCandidates = () => {
  const [shortlistedCandidates, setShortlistedCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [candidateToDelete, setCandidateToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [searchInput, setSearchInput] = useState(""); // Step 1: Search State
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 6;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleViewMoreClick = (candidate) => {
    openModal();
    setCandidateDetails(candidate);
  };

  const fetchShortlistedCandidates = async () => {
    try {
      const response = await fetch(
        "https://backend.internsbee.in/api/applyInternship/shortlisted"
      );
      const data = await response.json();
      console.log(data);
      setShortlistedCandidates(data);
    } catch (error) {
      console.error("Error fetching shortlisted candidates:", error);
    }
  };

  useEffect(() => {
    fetchShortlistedCandidates();
  }, []);

  const handleDeleteClick = (candidate) => {
    setCandidateToDelete(candidate);
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setCandidateToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `https://backend.internsbee.in/api/applyInternship/shortlisted/${candidateToDelete._id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        await fetchShortlistedCandidates();
      } else {
        console.error("Failed to delete shortlisted candidate");
      }
    } catch (error) {
      console.error("Error deleting shortlisted candidate:", error);
    }
    setShowConfirmation(false);
    setCandidateToDelete(null);
  };

  const filteredCandidates = shortlistedCandidates.filter(candidate =>
    candidate.InternName.toLowerCase().includes(searchInput.toLowerCase()) ||
    candidate.empName.toLowerCase().includes(searchInput.toLowerCase()) ||
    candidate.location.toLowerCase().includes(searchInput.toLowerCase())
  ); // Step 2: Filter Candidates

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const DateFormate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const totalPages = Math.ceil(filteredCandidates.length / entriesPerPage);
  const paginatedCandidates = filteredCandidates.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  return (
    <>
      <Navbar />
      <div className="displaycontent flex h-screen">
        <Sidebar />
        <div className="flex justify-center">
          <div className="shortlistdiv p-10 flex ml-44">
            <div className="shortlistdiv">
              <h1 className="flex justify-center text-3xl font-bold mt-8 relative">
                Shortlisted Candidates
              </h1>
              {/* Search Bar */}
              <div className="search-bar relative mb-6 w-[80%] mx-auto mt-4">
                <input
                  type="text"
                  placeholder="Search by Name, Company Name or Location"
                  className="w-full p-2 pl-10 pr-3 border-2 border-amber-500 rounded"
                  value={searchInput} // Step 3: Bind input value to search state
                  onChange={(e) => setSearchInput(e.target.value)} // Update search input state
                />
                <span>
                  <FaSearch className="absolute right-0 top-0 mt-[10px] mr-2" />
                </span>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
                  <thead className="bg-gray-100">
                    <tr className="hover:bg-gray-50">
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Name</th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Company Name</th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Location</th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Actions</th>
                      <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Delete</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {filteredCandidates.map((candidate, index) => ( // Use filteredCandidates for rendering
                      <tr key={candidate._id} className="text-left">
                        <td className="py-2 px-4 border-b text-lg">{candidate.InternName}</td>
                        <td className="py-2 px-4 border-b text-lg">{capitalizeFirstLetter(candidate.empName)}</td>
                        <td className="py-2 px-4 border-b text-lg">{capitalizeFirstLetter(candidate.location)}</td>
                        <td className="whitespace-nowrap">
                          <div className="px-4 py-1 text-left">
                            <button className="bg-amber-400 text-black hover:bg-amber-200 p-1 sm:p-2 cursor-pointer" onClick={() => handleViewMoreClick(candidate)}>
                              <VisibilityOutlinedIcon className="text-xs sm:text-sm" />
                            </button>
                          </div>
                        </td>
                        <td className="py-2 px-4 border-b">
                          <div className="px-2 py-1 text-left">
                            <button className="bg-amber-400 text-black hover:bg-amber-200 p-2 sm:p-2 cursor-pointer" onClick={() => handleDeleteClick(candidate)}>
                              <FaTrash />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* Pagination */}
              <div className="pagination flex justify-center items-center mt-4">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
                >
                  <FaChevronLeft />
                </button>
                <div className="pagination-info mx-2 text-amber-600 px-1 py-1">
                  {currentPage} of {totalPages}
                </div>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
                >
                  <FaChevronRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="confirmation-modal">
          <p>Are you sure you want to delete this candidate?</p>
          <button onClick={handleConfirmDelete}>Yes</button>
          <button className="not-delete" onClick={handleCancelDelete}>
            No
          </button>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className='flex justify-center'><h1 className="text-xl font-bold mb-4">Student Details</h1></div>
            <div>
              <p className='p-2'>
                <strong>Intern Name:</strong> {candidateDetails?.InternName}
              </p>

              <p className='p-2'><strong>Intern Email:</strong> {candidateDetails?.InternEmail} </p>

              <p className='p-2'><strong>Intern Number:</strong> {candidateDetails?.InternNumber} </p>

              <p className='p-2'><strong>Skills:</strong> {candidateDetails?.skills} </p>

              <p className='p-2'><strong>Location:</strong> {candidateDetails?.location} </p>

              <p className='p-2'><strong>Employer Name:</strong> {candidateDetails?.empName} </p>

              <p className='p-2'><strong>Position:</strong> {candidateDetails?.position} </p>

              <p className='p-2'><strong>Stipend:</strong> {candidateDetails?.stipend} </p>

              <p className='p-2'><strong>Job Title:</strong> {candidateDetails?.job_Title} </p>

              <p className='p-2'><strong>Applied Date:</strong> {DateFormate(candidateDetails?.appliedDate)} </p>

            </div>
            <div className='flex justify-center p-2'>
              <button className="mt-4 bg-red-500 text-white px-4 py-2 rounded" onClick={closeModal}>Close</button>
            </div>

          </div>
        </div>
      )}

    </>
  );
};

export default ShortlistedCandidates;
