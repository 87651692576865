import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./Components/Sidebar/Sidebar";
import Navbar from "./Components/Navbar/Navbar";
import Login from "./Components/Login/Login";
import SubscriptionMonthly from "./Components/Subscription/SubscriptionMonthly";
import Footer from "./Components/Footer/Footer";
import ShortlistedCandidates from "./Components/ShortlistedCandidates/ShortlistedCandidates";
import JobList from "./Components/AppliedStudenst/JobList";
import Candidates from "./Components/Candidates/Candidates";
import AdminDashboard from "./Components/AdminDashboard/AdminDashboard";
import InternshipListed from "./Components/AdminDashboard/container/InternshipListed";
import RecentApplicants from "./Components/AdminDashboard/container/RecentApplicants";
import AboutUs from "./Components/AboutUs/AboutUs";
import ViewInternship from "./Components/ViewPostInternship/ViewInternship";
import ViewProfile from "./Components/ViewProfile/ViewProfile";
import Blog from "./Components/Blog/Blog";
import CreateBlog from "./Components/Blog/CreateBlog";
import SubscriptionAnnually from "./Components/Subscription/SubscriptionAnnually";
import AppliedStudenst from "./Components/AppliedStudenst/AppliedStudenst";
import StudentSubscriptionMonthly from "./Components/Subscription/StudentSubscriptionMonthly";
import StudentSubscriptionFree from "./Components/Subscription/StudentSubscriptionFree";
import GenerateReport from "./Components/Generate Report/GenerateReport";
import TPO from "./Components/TPO/TPO";
import { EmployerPackage } from "./Components/Employerpackage/EmployerPackage";
import StudentDetails from "./Components/StudentDetails/StudentDetails";
import SendVerifyOTP from "./Components/ChangePassword/SendVerifyOTP";
import ResetPassword from "./Components/ChangePassword/ResetPassword";
import PublicRoute from "./Components/protectedRoute/PublicRoute";
import ProtectedRoute from "./Components/protectedRoute/ProtectedRoute";



const App = () => {

  return (
    <div>
         <Router>
      <Routes>
        {/* Public Route */}
        <Route path='/' element={<PublicRoute element={<Login />} restrictedPath="/admindashboard" />} />
        <Route path='/resetpass' element={<PublicRoute element={<SendVerifyOTP />} restrictedPath="/admindashboard" />} />

        {/* Protected Routes */}
        <Route path="/sidebar" element={<ProtectedRoute element={<Sidebar />} />} />
        <Route path="/admindashboard" element={<ProtectedRoute element={<AdminDashboard />} />} />
        <Route path="/navbar" element={<ProtectedRoute element={<Navbar />} />} />
        <Route path="/InternshipListed" element={<ProtectedRoute element={<InternshipListed />} />} />
        <Route path="/RecentApplicants" element={<ProtectedRoute element={<RecentApplicants />} />} />
        <Route path="/candidates" element={<ProtectedRoute element={<Candidates />} />} />
        <Route path="/viewshortlistedcandidates" element={<ProtectedRoute element={<ShortlistedCandidates />} />} />
        <Route path="/studuntdetails" element={<ProtectedRoute element={<StudentDetails />} />} />
        <Route path="/viewjoblist" element={<ProtectedRoute element={<JobList />} />} />
        <Route path="/subscriptionmonthly" element={<ProtectedRoute element={<SubscriptionMonthly />} />} />
        <Route path="/aboutus" element={<ProtectedRoute element={<AboutUs />} />} />
        <Route path="/postinternship" element={<ProtectedRoute element={<ViewInternship />} />} />
        <Route path="/viewprofile" element={<ProtectedRoute element={<ViewProfile />} />} />
        <Route path="/footer" element={<ProtectedRoute element={<Footer />} />} />
        <Route path="/blog" element={<ProtectedRoute element={<Blog />} />} />
        <Route path="/create-blog" element={<ProtectedRoute element={<CreateBlog />} />} />
        <Route path="/subscriptionannually" element={<ProtectedRoute element={<SubscriptionAnnually />} />} />
        <Route path="/newappliedstudents" element={<ProtectedRoute element={<AppliedStudenst />} />} />
        <Route path="/studentmonthlysubcription" element={<ProtectedRoute element={<StudentSubscriptionMonthly />} />} />
        <Route path="/studentfreesubcription" element={<ProtectedRoute element={<StudentSubscriptionFree />} />} />
        <Route path="/generatereport" element={<ProtectedRoute element={<GenerateReport />} />} />
        <Route path="/viewemployerpackages" element={<ProtectedRoute element={<EmployerPackage />} />} />
        <Route path="/changepassword/:token" element={<ProtectedRoute element={<ResetPassword />} />} />
        <Route path="/tpo" element={<ProtectedRoute element={<TPO />} />} />
      </Routes>
    </Router>
       
    </div>
  );
};

export default App;
