import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import './Joblist.css'
import '../ResponsiveCss/Responsive.css';
import Footer from '../Footer/Footer';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DatePicker from 'react-datepicker';

const AppliedStudenst = () => {
  const [candidates, setCandidates] = useState([]);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [searchFilters, setSearchFilters] = useState({
    jobTitle: '',
    internName: '',
    employer: '',
    status: '',
    applyDate: null
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [candidateToDelete, setCandidateToDelete] = useState(null);
  const [jobList, setJobList] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusOptions] = useState(['pending', 'shortlisted']);

  const fetchData = async () => {
    try {
      const response = await fetch("https://backend.internsbee.in/api/applyInternship");
      const data = await response.json();
      setCandidates(data);
      setFilteredCandidates(data); // Set filtered candidates to all candidates initially
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Filter candidates based on search filters
  useEffect(() => {
    let filtered = candidates.filter((candidate) => {
      const isJobTitleMatch = candidate.job_Title.toLowerCase().includes(searchFilters.jobTitle.toLowerCase());
      const isInternNameMatch = candidate.InternName.toLowerCase().includes(searchFilters.internName.toLowerCase());
      const isEmployerMatch = candidate.empName.toLowerCase().includes(searchFilters.employer.toLowerCase());
      const isStatusMatch = candidate.status.toLowerCase().includes(searchFilters.status.toLowerCase());
      const isApplyDateMatch = !searchFilters.applyDate || DateFormate(candidate.appliedDate) === DateFormate(searchFilters.applyDate);

      return isJobTitleMatch && isInternNameMatch && isEmployerMatch && isStatusMatch && isApplyDateMatch;
    });

    setFilteredCandidates(filtered);
  }, [searchFilters, candidates]);

  const handleSearchChange = (e) => {
    setSearchFilters({
      ...searchFilters,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (date) => {
    setSearchFilters({
      ...searchFilters,
      applyDate: date,
    });
  };

  const DateFormate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleDeleteClick = (candidate) => {
    setCandidateToDelete(candidate);
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setCandidateToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(`https://backend.internsbee.in/api/applyInternship/${candidateToDelete._id}`, {
        method: "DELETE",
      });

      if (response.ok) {
        await fetchData();
      } else {
        console.error("Failed to delete candidate");
      }
    } catch (error) {
      console.error("Error deleting candidate:", error);
    }

    setShowConfirmation(false);
    setCandidateToDelete(null);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleViewMoreClick = (candidate) => {
    openModal();
    setJobList(candidate);
  };

  return (
    <>
      <Navbar />
      <div className="displaycontent flex h-screen">
        <Sidebar />
        <div className="  flex justify-center pl-[10rem]">
          <div>
            <h1 className=" HeadingNewJobList text-3xl font-bold mb-4 mt-8 text-center" style={{ fontFamily: 'Roboto, sans-serif' }}>
              Applied Students for Internship
            </h1>
            {/* Filter Inputs */}

            <div className="flex space-x-2 mb-4">
              <input
                type="text"
                name="jobTitle"
                placeholder="Search by Job Title"
                value={searchFilters.jobTitle}
                onChange={handleSearchChange}
                className="p-2 border-2 border-amber-500 rounded"
              />
              <input
                type="text"
                name="internName"
                placeholder="Search by Job Applicant"
                value={searchFilters.internName}
                onChange={handleSearchChange}
                className="p-2 border-2 border-amber-500 rounded"
              />
              <input
                type="text"
                name="employer"
                placeholder="Search by Employer"
                value={searchFilters.employer}
                onChange={handleSearchChange}
                className="p-2 border-2 border-amber-500 rounded"
              />
              <select
                name="status"
                value={searchFilters.status}
                onChange={handleSearchChange}
                className="p-2 border-2 border-amber-500 rounded"
              >
                <option value="">Select Status</option>
                {statusOptions.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
              <DatePicker
                selected={searchFilters.applyDate}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                placeholderText="Apply Date"
                className="p-2 border-2 border-amber-500 rounded"
              />
            </div>


            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
                <thead className="bg-gray-100">

                  <tr>

                    <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Job Title</th>
                    <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Job Applicant</th>
                    <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Employer</th>
                    <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Status</th>
                    <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Apply Date</th>
                    <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Profile</th>
                    <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredCandidates.map((candidate, index) => (
                    <tr key={candidate._id} className="hover:bg-gray-50">
                      <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider ">{candidate.job_Title}</td>
                      <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{candidate.InternName}</td>
                      <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{candidate.empName}</td>
                      <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{candidate.status}</td>
                      <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{DateFormate(candidate.appliedDate)}</td>
                      <td className="whitespace-nowrap">
                        <div className="px-6 py-1 text-left">
                          <button className='bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer' onClick={() => handleViewMoreClick(candidate)}>
                            <VisibilityOutlinedIcon className='' />
                          </button>
                        </div>
                      </td>
                      <td>
                        <button onClick={() => handleDeleteClick(candidate)} className="bg-amber-400 text-black hover:bg-amber-200 p-3 cursor-pointer">
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>

              </table>
            </div>
          </div>


        </div>
      </div>


      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="confirmation-modal">
          <p>Are you sure you want to delete this candidate?</p>
          <button onClick={handleConfirmDelete}>Yes</button>
          <button className="not-delete" onClick={handleCancelDelete}>No</button>
        </div>
      )}


      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <div className='flex justify-center'><h1 className="text-xl font-bold mb-4">Student Details</h1></div>
            {/* Display detailed information here */}
            <div>
              <p className="p-2"><strong>Applicant Name:</strong> {jobList.InternName}</p>
              <p className='p-2'><strong>Email:</strong> {jobList.InternEmail}</p>
              <p className='p-2'><strong>Intern Number:</strong> {jobList.InternNumber}</p>
              <p className='p-2'><strong>Location:</strong> {jobList.location}</p>
              <p className='p-2'><strong>Employer Name:</strong> {jobList.empName}</p>
              <p className='p-2'><strong>Job Description:</strong> {jobList.job_Description}</p>
              <p className='p-2'><strong>Position:</strong> {jobList.position}</p>
              <p className='p-2'><strong>Skills:</strong> {jobList.skills}</p>
              <p className='p-2'><strong>Stipend:</strong> {jobList.stipend}</p>
              <p className='p-2'><strong>Job Title:</strong> {jobList.job_Title}</p>
              <p className='p-2'><strong>Applied Date:</strong> {DateFormate(jobList.appliedDate)}</p>

              {/* Add more labels for additional fields */}
            </div>
            <div className="flex justify-center p-2">
              <button
                className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}



    </>
  )
}

export default AppliedStudenst