import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import '../ResponsiveCss/Responsive.css';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const ViewInternship = () => {
    const [internships, setInternships] = useState([]);
    const [filteredInternships, setFilteredInternships] = useState([]);
    const [candidateDetails, setCandidateDetails] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // State for search input
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://backend.internsbee.in/api/postinternship');
                const data = await response.json();
                setInternships(data);
                setFilteredInternships(data); // Initialize filtered internships
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Filter internships based on search term
    useEffect(() => {
        const results = internships.filter(internship => 
            (internship.job_Title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            internship.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
            internship.company_Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            internship.empName.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        setFilteredInternships(results);
    }, [searchTerm, internships]);

    const handleViewMoreClick = (internship) => {
        openModal();
        setCandidateDetails(internship);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const DateFormate = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <>
            <Navbar />
            <div className="displaycontent flex h-screen">
                <Sidebar />
                <div className="ml-48 ">
                    <div className="max-w-full p-4">
                        <h1 className="Heading text-3xl font-bold mb-4 mt-4 text-center">Available Internship Details</h1>

                        {/* Single Search Input */}
                        <div className="mb-4">
                            <input
                                type="text"
                                placeholder="Search by Job Title, Location, Company Name, or Employer Name"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="border-2 border-amber-500 rounded rounded p-2 w-full"
                            />
                        </div>

                        <div className="">
                            <div className="overflow-x-auto">
                                <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
                                    <thead className="bg-gray-100">
                                        <tr>
                                            <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Job Title</th>
                                            <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Location</th>
                                            <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Company Name</th>
                                            <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Employer Name</th>
                                            <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">View More</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {filteredInternships.map((internship) => (
                                            <tr key={internship._id} className="hover:bg-gray-50">
                                                <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{internship.job_Title}</td>
                                                <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{internship.location}</td>
                                                <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{internship.company_Name}</td>
                                                <td className="px-2 py-1 text-left text-xs sm:text-sm font-medium text-gray-700 tracking-wider">{internship.empName}</td>

                                                <td className="whitespace-nowrap">
                                                    <div className="px-6 py-1 text-left">
                                                        <button className='bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer' onClick={() => handleViewMoreClick(internship)}>
                                                            <VisibilityOutlinedIcon className='' />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <div className='flex justify-center'><h1 className="text-xl font-bold mb-4">Student Details</h1></div>
                        {/* Display detailed information here */}
                        <div>
                            <p className="p-2"><strong>Employer Name</strong>: {candidateDetails?.empName}</p>
                            <p className="p-2"><strong>Email:</strong> {candidateDetails?.empEmail}</p>
                            <p className="p-2"><strong>empPhone:</strong> {candidateDetails?.empPhone}</p>
                            <p className="p-2"><strong>Job Title:</strong> {candidateDetails?.job_Title}</p>
                            <p className="p-2"><strong>Location:</strong> {candidateDetails?.location}</p>
                            <p className="p-2"><strong>Company Name:</strong> {candidateDetails?.company_Name}</p>
                            <p className="p-2"><strong>Start Date:</strong> {candidateDetails ? DateFormate(candidateDetails.start_Date) : ''}</p>
                            <p className="p-2"><strong>End Date:</strong> {candidateDetails ? DateFormate(candidateDetails.end_Date) : ''}</p>
                            <p className="p-2"><strong>Job Type:</strong> {candidateDetails?.job_Type}</p>
                            <p className="p-2"><strong>Skills:</strong> {candidateDetails?.skills}</p>
                            <p className="p-2"><strong>Position:</strong> {candidateDetails?.position}</p>
                            <p className="p-2"><strong>Stipend:</strong> {candidateDetails?.stipend}</p>
                        </div>
                        <div className="flex justify-center p-2">
                            <button
                                className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ViewInternship;
