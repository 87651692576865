import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import InternshipListed from "./InternshipListed";
import RecentApplicants from "./RecentApplicants";



const TabInternApplic = () => {
  const [activeSection, setActiveSection] = useState("Intern ShipListed");

  const handleOpen = (section) => {
    setActiveSection(section);
  };

  // Define a function to check if a section is active and return the appropriate class
  const getTabClasses = (section) => {
    return activeSection === section
      ? "text-amber-500 border-b-2 border-amber-500 px-4 py-2 font-bold"
      : "text-black px-4 py-2 hover:border-b-2 hover:text-amber-500 cursor-pointer font-bold";
  };

  return (
   

    <div className="w-full h-full flex-grow flex flex-col">
      
          <div className="items-center flex justify-start gap-4 bg-amber-200 border-b-2 border-white ">
            <p
              className={getTabClasses("Intern ShipListed")}
              onClick={() => handleOpen("Intern ShipListed")}
            >
              Internship Listed
            </p>
            <p
              className={getTabClasses("Recent Applicants")}
              onClick={() => handleOpen("Recent Applicants")}
            >
              Recent Applicants
            </p>
          </div>

          <div className=" flex items-start justify-center  shadow-2xl border border-transparent rounded-md ">
            {activeSection === "Intern ShipListed" && <InternshipListed />}
            {activeSection === "Recent Applicants" && <RecentApplicants />}
          </div>
        
      </div>
   
  );
};

export default TabInternApplic;
