import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './../Navbar/Navbar';
import Sidebar from './../Sidebar/Sidebar';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const TpoList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 5;

    // State for the search filter
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        axios.get('https://backend.internsbee.in/api/tpo/')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data: {error.message}</p>;

    // Filter data based on the search term
    const filteredData = data.filter(item => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return (
            item.name.toLowerCase().includes(lowerCaseSearchTerm) ||
            item.collage.toLowerCase().includes(lowerCaseSearchTerm) ||
            item.numberOfIntern.toString().includes(lowerCaseSearchTerm) ||
            item.educationField.toLowerCase().includes(lowerCaseSearchTerm)
        );
    });

    // Calculate total pages based on the filtered data length
    const totalPages = Math.ceil(filteredData.length / entriesPerPage);
    const paginatedApplicants = filteredData.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className="flex flex-1">
                <Sidebar className="h-full" />
                <div className="w-full p-8 overflow-auto">
                    <div className="container mx-auto p-4">
                        <h1 className="text-2xl font-bold mb-4 flex justify-center">TPO List</h1>
                        {/* Single Search Box */}
                        <div className="mb-4">
                            <input
                                type="text"
                                placeholder="Search by Name, College, Number of Interns, or Education Field"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="p-2 border-2 border-amber-500 rounded w-full"
                            />
                        </div>
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Name</th>
                                        <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">College</th>
                                        <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Email</th>
                                        <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Contact Number</th>
                                        <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Number of Interns</th>
                                        <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Education Field</th>
                                        <th className="px-4 py-2 text-left text-xs sm:text-sm font-bold text-gray-700 uppercase tracking-wider">Remark</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {paginatedApplicants.map(item => (
                                        <tr key={item._id} className="hover:bg-gray-100">
                                            <td className="px-4 py-2 text-left text-sm font-medium text-gray-700 tracking-wider">{item.name}</td>
                                            <td className="px-4 py-2 text-left text-sm font-medium text-gray-700 tracking-wider">{item.collage}</td>
                                            <td className="px-4 py-2 text-left text-sm font-medium text-gray-700 tracking-wider">{item.email}</td>
                                            <td className="px-4 py-2 text-left text-sm font-medium text-gray-700 tracking-wider">{item.contactNumber}</td>
                                            <td className="px-4 py-2 text-left text-sm font-medium text-gray-700 tracking-wider">{item.numberOfIntern}</td>
                                            <td className="px-4 py-2 text-left text-sm font-medium text-gray-700 tracking-wider">{item.educationField}</td>
                                            <td className="px-4 py-2 text-left text-sm font-medium text-gray-700 tracking-wider">{item.remark}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* Pagination */}
                        <div className="pagination flex justify-center items-center mt-4">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
                            >
                                <FaChevronLeft />
                            </button>
                            <div className="pagination-info mx-2 text-amber-600 px-1 py-1">
                                {currentPage} of {totalPages}
                            </div>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="pagination-button bg-amber-400 text-black hover:bg-amber-200 p-2 cursor-pointer"
                            >
                                <FaChevronRight />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TpoList;
